import { styled } from "styled-components"
import { Link } from "react-router-dom"
import {ReactComponent as Vk} from '../../components/img/svg/Vkcolor.svg'

const Oblochka = styled.div`
    display: flex;
    padding: 30px 0;
    gap: 40px;
`
const LinkSoc = styled(Link)`
    &:hover:first-child{        
        rect{fill:#E2E8ED;}
        path{fill:#0077FF; }        
    }
    &:hover:last-child{
        rect{fill:#E2E8ED;}
        path{fill:#F40000; }
    }
    &:hover:nth-child(2){
        rect{fill:#E2E8ED;}
        path{fill:#F79A38; }
    }
`


export const LinkSotcSeti = () => {
    return (
        <Oblochka>
            <LinkSoc to='https://vk.com/udmddn'> <Vk/> </LinkSoc>
            <LinkSoc to="https://ok.ru/group/56587615076542"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#F79A38"/>
<path d="M26.2376 22.7928C26.7624 22.2681 26.8373 21.5185 26.3876 20.7689C26.0128 20.1692 25.3381 19.8694 24.5885 20.0193C24.2887 20.0942 23.9888 20.2442 23.689 20.469C21.3652 22.0432 18.2168 21.9683 15.893 20.3941C15.7431 20.3191 15.5932 20.2442 15.4433 20.1692C14.6187 19.7944 13.7941 19.9443 13.3444 20.6939C12.8196 21.4435 12.8946 22.2681 13.5692 22.9428C13.7192 23.0927 13.8691 23.1676 13.944 23.3176L14.019 23.3925C15.0685 24.2171 16.3428 24.7418 17.9919 24.9667L17.0174 25.9412C16.1929 26.7658 15.2933 27.6653 14.4688 28.4899C14.2439 28.7148 13.944 29.0896 13.944 29.6143C13.944 30.2889 14.3188 30.9636 14.9935 31.2634C15.2184 31.4133 15.4433 31.4133 15.6681 31.4133C16.0429 31.4133 16.4927 31.2634 16.7926 30.8886C17.917 29.7642 18.8165 28.8647 19.716 27.9651C19.791 27.8902 19.791 27.8902 19.791 27.8902C19.791 27.8902 19.866 27.8902 19.866 27.9651C20.4656 28.6398 21.1403 29.2395 21.74 29.9141C22.0398 30.214 22.3397 30.5138 22.6395 30.8137C23.0143 31.1885 23.3142 31.3384 23.7639 31.3384C24.4386 31.4134 25.1882 30.9636 25.488 30.2889C25.7879 29.6892 25.638 28.9396 25.1132 28.4899C24.3636 27.7403 23.614 26.9157 22.7894 26.1661L21.5151 24.8917C22.1898 24.8168 22.9394 24.6669 23.614 24.367C24.7384 23.9922 25.563 23.4675 26.2376 22.7928Z" fill="white"/>
<path d="M19.791 20.6939C23.0143 20.6939 25.638 18.1452 25.638 14.9219C25.638 13.3477 25.0383 11.8485 23.9138 10.7241C22.7894 9.59969 21.3652 9 19.866 9C16.6426 9 14.094 11.6236 14.019 14.772C14.019 16.3462 14.6187 17.7704 15.7431 18.8948C16.7926 20.0942 18.2168 20.6939 19.791 20.6939ZM18.1419 13.1978C18.5916 12.7481 19.1913 12.5232 19.791 12.5232C21.1403 12.5232 22.1148 13.5726 22.1148 14.847C22.1148 16.1963 21.0653 17.1707 19.791 17.1707C18.4417 17.1707 17.4672 16.1213 17.4672 14.847C17.4672 14.2473 17.6921 13.6476 18.1419 13.1978Z" fill="white"/>
<path d="M26.2376 22.7928C26.7624 22.2681 26.8373 21.5185 26.3876 20.7689C26.0128 20.1692 25.3381 19.8694 24.5885 20.0193C24.2887 20.0942 23.9888 20.2442 23.689 20.469C21.3652 22.0432 18.2168 21.9683 15.893 20.3941C15.7431 20.3191 15.5932 20.2442 15.4433 20.1692C14.6187 19.7944 13.7941 19.9443 13.3444 20.6939C12.8196 21.4435 12.8946 22.2681 13.5692 22.9428C13.7192 23.0927 13.8691 23.1676 13.944 23.3176L14.019 23.3925C15.0685 24.2171 16.3428 24.7418 17.9919 24.9667L17.0174 25.9412C16.1929 26.7658 15.2933 27.6653 14.4688 28.4899C14.2439 28.7148 13.944 29.0896 13.944 29.6143C13.944 30.2889 14.3188 30.9636 14.9935 31.2634C15.2184 31.4133 15.4433 31.4133 15.6681 31.4133C16.0429 31.4133 16.4927 31.2634 16.7926 30.8886C17.917 29.7642 18.8165 28.8647 19.716 27.9651C19.791 27.8902 19.791 27.8902 19.791 27.8902C19.791 27.8902 19.866 27.8902 19.866 27.9651C20.4656 28.6398 21.1403 29.2395 21.74 29.9141C22.0398 30.214 22.3397 30.5138 22.6395 30.8137C23.0143 31.1885 23.3142 31.3384 23.7639 31.3384C24.4386 31.4134 25.1882 30.9636 25.488 30.2889C25.7879 29.6892 25.638 28.9396 25.1132 28.4899C24.3636 27.7403 23.614 26.9157 22.7894 26.1661L21.5151 24.8917C22.1898 24.8168 22.9394 24.6669 23.614 24.367C24.7384 23.9922 25.563 23.4675 26.2376 22.7928Z" fill="white"/>
</svg>
</LinkSoc>
            <LinkSoc to="https://www.youtube.com/channel/UC-RrkFEUfNc1njw2LIknsbQ"><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="20" fill="#F40000"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.2625 27.0568C10.8702 26.9408 10.5123 26.7343 10.219 26.4549C9.9256 26.1754 9.7054 25.8312 9.57695 25.4512C8.84292 23.4874 8.62543 15.2869 10.0391 13.6681C10.5096 13.1414 11.1739 12.8172 11.8878 12.7658C15.6802 12.3677 27.3975 12.4208 28.7432 12.8985C29.1217 13.0183 29.4675 13.2203 29.7547 13.4891C30.0419 13.758 30.263 14.0868 30.4015 14.451C31.2035 16.4812 31.2307 23.8589 30.2928 25.8095C30.044 26.3173 29.6275 26.7285 29.1102 26.9772C27.6965 27.6672 13.1383 27.6539 11.2625 27.0568ZM17.2842 23.2087L24.0808 19.7587L17.2842 16.2821V23.2087Z" fill="white"/>
</svg>
</LinkSoc>
        </Oblochka>
    )
}