import { $authHost,$host } from "../http/index";
import { jwtDecode } from 'jwt-decode'


export const login = async (email, password) => {
    const {data} = await $host.post('/api/user/login', {email, password})
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}

export const check = async () => {
    const {data} = await $authHost.post('/api/user/auth')
    localStorage.setItem('token', data.token)
    return jwtDecode(data.token)
}