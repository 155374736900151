import { Main } from "../shared/ReusedStyles"
import { MainColor,TextContent,Textp } from '../shared/ReusedStyles'

export const InfoOrg = () => {
    return(
        <Main>
            <MainColor>
                <TextContent>
                    <h1>Сведения об организации</h1>
                    <Textp>Бюджетное учреждение Удмуртской Республики «Дом Дружбы народов» создан в 2008 году в целях укрепления общегражданской Российской идентичности, сохранения и популяризация этнокультурного и языкового многообразия Удмуртии, воспитания культуры межнационального взаимодействия. Учредителем учреждения является Министерство национальной политики.
                        <br />В своей работе мы:
                        <br />- реализуем целевые государственные программы национального развития в области культуры, образования, патриотического воспитания граждан;
                        <br />- оказываем государственную поддержку деятельности национально-культурных объединений республики, создания благоприятных условий для этнокультурного развития народов Удмуртии;
                        <br />- укрепляем общероссийскую гражданскую идентичность на основе соблюдения прав и свобод человека;
                        <br />- противодействуем национальному экстремизму и ксенофобии.
                    </Textp>
                </TextContent>
            </MainColor>
        
        </Main>
    )
}