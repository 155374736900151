export const Pahts_administration = {
    direcory: 'dir',
    secretary: 'secretary',
    deputy_director_1: 'dep_dir_1',
    deputy_director_2: 'dep_dir_2',
    department_tex_obsl: 'dep_tex_obsl',
    department_tex_snab: 'dep_tex_snab',
    department_admin: 'dep_dir_admin',
    depertment_relations: 'dep_dep_relations',
    department_events: 'dep_dep_events',
    departnent_education: 'dep_dep_education',
    department_project: 'dep_dep_project',
    department_info: 'dep_dep_info',
    department_admin_res: 'dep_dep_admin_res'
 }