export const Pahts = {
   indormation: 'about',
   structure: 'structure',
   doc: 'documents',
   admin: 'admin',
   postingspost: 'post',
   note: 'note',
   hrental: 'rent',
   news: 'new',
   onepostpage: 'postpage'
}